import React, {useContext, useState} from 'react'

export const LoadingContext = React.createContext()
export const UpdateLoadingContext = React.createContext()

export function useIsLoading(){
    return useContext(LoadingContext)
}

export function useToggleLoading(){
    return useContext(UpdateLoadingContext)
}

export default function LoadingProvider({ children}) {
    const [isLoading, setIsLoading] = useState (false)

    function toggleIsLoading(value){
        console.log('toggleIsLoading', value)
        setIsLoading(value)
    }

  return (
   <LoadingContext.Provider value={isLoading}>
    <UpdateLoadingContext.Provider value={toggleIsLoading}>
        {children}
    </UpdateLoadingContext.Provider>
   </LoadingContext.Provider>
  )
}
