import React, {useContext, useState} from 'react';


const InfoContext = React.createContext();
const InfoUpdateContext = React.createContext();

export function useInfo(){
    return useContext(InfoContext);
}

export function useInfoUpdate(){
    return useContext(InfoUpdateContext);
}



export function InformationProvider ({children}){
    const [info, setInfo] = useState({message: "", type: "info"});
   

    function updateInfo (info) {
        console.log("updating info")
        setInfo(info)
        setTimeout(clearInfo, 5000)
    }

    function clearInfo () {
        console.log("clearing info")
        setInfo("")
    }
       
return (
    <InfoContext.Provider value={info}>
        <InfoUpdateContext.Provider value={updateInfo}>
        {children}
        </InfoUpdateContext.Provider>
    </InfoContext.Provider>
)
}