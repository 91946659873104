import React, { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { devopsRequest } from "../authConfig";
import { loginRequest } from "../authConfig";
import { FetchProjects, FetchSuitesByPlan } from '../devops';
import { useToggleLoading, useIsLoading } from '../LoadingContext';
import Form from 'react-bootstrap/Form';

export const SuiteDropDown = ({selectedOrg, selectedProject, selectedPlan, setSelectedSuite}) => {
    const [suites, setSuites] = useState([]);
    const { instance, accounts } = useMsal();
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

     const getSuiteData = (data) => {
        if (data.length <= 0) {
            setSuites([])
            return;
        }
        setSuites(data) 
        setSelectedSuite(data[0])
     }


     useEffect(() => {
        const controller = new AbortController();
        async function RequestSuites() {
            instance
            .acquireTokenSilent({
                ...devopsRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                toggleIsLoading(true)
                await FetchSuitesByPlan(selectedOrg.accountName, selectedProject.name, selectedPlan.id, getSuiteData,response.accessToken, controller.signal);
                toggleIsLoading(false)
            }).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required") {
                    instance.acquireTokenRedirect({...loginRequest,
                        account: accounts[0],
                    });
                }
            });
        }
       
        if (isLoading) return;
        if (selectedPlan === undefined || selectedProject === undefined || Object.keys(selectedPlan).length <= 0){
            setSuites([])
            return;
        } 
        if (Object.keys(selectedPlan).length > 0){
            RequestSuites();
        } 

        return () => {
            controller.abort();
        }
    }, [selectedPlan]) 


    const handler = (e) => {
        var suite = suites.find((suite) => suite.id == e.target.value)
       setSelectedSuite(suite)
       
    }
   
    return (
        <>
        <Form.Select disabled={isLoading} size="lg" onChange={handler}>
           {/*  {projectData.map(a => <option key={a.id}>{a.name}</option>)} */}
           {suites.length > 0 ? suites.map(a=> <option key={a.id} value={a.id}>{a.name}</option>) : <option key="0">No suites found</option>}
        </Form.Select>   
        </> 
    ); 
};