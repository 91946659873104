import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useMsal } from '@azure/msal-react';
import { FetchRootQuerys } from "../devops";
import { devopsRequest, graphConfig, loginRequest } from "../authConfig";
import { useIsLoading, useToggleLoading } from "../LoadingContext";
import { useAuthorization } from "../AuthorizationContext";
import { useInfo, useInfoUpdate} from "../InformativeContext";

export const RootQueryDropDown = ({setSelectedRoot, selectedOrg, selectedProject}) => {

    const [querys, setQuerys] = useState([]);
    const { instance, accounts } = useMsal();
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

    const getQueryData = (data) => { 
        console.log(data)
        if (data.value.length <= 0){
            setQuerys([]) 
            setSelectedRoot({})
            return;
        } 
        setQuerys(data.value) 
        setSelectedRoot(data.value[0])
     }

    useEffect(() => {  
        console.log("entering useeffect")
        const controller = new AbortController();
        async function RequestQuerys() {
            instance
            .acquireTokenSilent({
                ...devopsRequest,
                account: accounts[0],
            })
            .then(async (response) => {   
                toggleIsLoading(true)
                console.log("fetching querys")
                await FetchRootQuerys(selectedOrg.accountName,selectedProject.name,getQueryData,response.accessToken, controller.signal);
                toggleIsLoading(false)
            }).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required" 
                || error.errorCode === "invalid_grant") {
                    console.log(error.errorCode)
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: accounts[0],
                    });
                }
            });
        }
        if (isLoading) return;
        console.log(Object.keys(selectedOrg).length)
        if (Object.keys(selectedOrg).length > 0){
            RequestQuerys();
        } 
        return () => {
            controller.abort();
        }
    }, [selectedOrg, selectedProject])

    const handler = (e) => {
        var query = querys.find((q) => q.name == e.target.value)
       setSelectedRoot(query)
       
    }

    return (
        <>
        <Form.Select disabled={isLoading} onChange={handler} size="lg">
       {/*      {orgs.map(a => <option key={a.accountId}>{a.accountName}</option>)} */}
            {querys.length > 0 ? querys.map(a=> <option key={a.id}>{a.name}</option>) : <option key="0">No Querys found</option>}
        </Form.Select>   
        </> 
    );
};