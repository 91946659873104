import React, { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { devopsRequest } from "../authConfig";
import { loginRequest } from "../authConfig";
import { callMsGraph, fetchOrganizations } from '../graph';
import { FetchProjects } from '../devops';
import { useIsLoading, useToggleLoading } from "../LoadingContext";
import Form from 'react-bootstrap/Form';

export const ProjectDropDown = ({selectedOrg,setSelectedProj}) => {
    const [projects, setProjects] = useState([]);
    const { instance, accounts } = useMsal();

    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

    const getProjData = (data) => {
        if (data.value.length <= 0){
            setProjects([]) 
            setSelectedProj({})
            return;
        }
        setProjects(data.value) 
        setSelectedProj(data.value[0])
     }

    useEffect(() => {
        const controller = new AbortController();
        async function RequestProjects() {
            instance
            .acquireTokenSilent({
                ...devopsRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                toggleIsLoading(true)
                await FetchProjects(selectedOrg.accountName,getProjData,response.accessToken, controller.signal);
                toggleIsLoading(false)
            }).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required") {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: accounts[0],
                    });
                }
            });
        }
        if (isLoading) return;
        if (Object.keys(selectedOrg).length > 0){
            RequestProjects();
        } 

        return () => {
            controller.abort();
        }
    }, [selectedOrg])  
    
    const handler = (e) => {
        var proj = projects.find((proj) => proj.name == e.target.value)
       setSelectedProj(proj)
    }

    return (
        <>
        <Form.Select disabled={isLoading} size="lg" onChange={handler}>
         {projects.length > 0 ? projects.map(a=> <option key={a.id}>{a.name}</option>) : <option key="0">No projects found</option>} 
        </Form.Select>   
        </> 
    );
};