import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useMsal } from '@azure/msal-react';
import { FetchQuerys } from "../devops";
import { devopsRequest, graphConfig, loginRequest } from "../authConfig";
import { useIsLoading, useToggleLoading } from "../LoadingContext";
import { useAuthorization } from "../AuthorizationContext";
import { useInfo, useInfoUpdate} from "../InformativeContext";

export const QueryDropDown = ({setSelectedQuery, selectedOrg, selectedProject, selectedRoot}) => {

    const [querys, setQuerys] = useState([]);
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

    const getQueryData = (data) => { 
        console.log(data)
      // if (data.hasChildren === false) return;
        if (data.hasChildren === false ||data.children.length <= 0){
            setQuerys([]) 
            setSelectedQuery({})
            return;
        } 
        setQuerys(data.children) 
        setSelectedQuery(data.children[0])
     }

    useEffect(() => {
        console.log(selectedRoot)
        function loadQuerys() {
            toggleIsLoading(true)  
            getQueryData(selectedRoot)
            toggleIsLoading(false)
        }
        if (isLoading) return;
        if (selectedRoot === undefined) return;
        console.log(Object.keys(selectedRoot).length)
        console.log(Object.keys(selectedRoot))
        if (Object.keys(selectedRoot).length > 0){
            loadQuerys();
        } 
    }, [selectedRoot])

    const handler = (e) => {
        var query = querys.find((q) => q.name == e.target.value)
       setSelectedQuery(query)
    }

    return (
        <>
        <Form.Select disabled={isLoading} onChange={handler} size="lg">
       {/*      {orgs.map(a => <option key={a.accountId}>{a.accountName}</option>)} */}
            {querys.length > 0 ? querys.map(a=> <option key={a.id}>{a.name}</option>) : <option key="0">No Querys found</option>}
        </Form.Select>   
        </> 
    );
};