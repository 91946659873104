import { useMsal } from '@azure/msal-react';
import React, {useContext, useState, useEffect} from 'react';
import { useIsLoading, useToggleLoading } from "./LoadingContext";

const AuthorizationContext = React.createContext();
const AuthorizationUpdateContext = React.createContext();


export function useAuthorization(){
    return useContext(AuthorizationContext);
}

export function useAuthorizationUpdate(){
    return useContext(AuthorizationUpdateContext);
}

export function AuthorizationProvider ({children}){
    const [isAuthorizied, setIsAuthorized] = useState(false);
    const { accounts } = useMsal();
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

    useEffect(() => {
      verifyTenant()
    }, [accounts])
    

    const handleVerify = (data) => {
        setIsAuthorized(data)
    }

    const toggleVerify = () => {
        verifyTenant();
    }

   async function verifyTenant () {
    if (accounts.length <= 0) return;
        toggleIsLoading(true)
        await fetch("https://prod-216.westeurope.logic.azure.com:443/workflows/76defe3e06574e28bafcc44576f3dac6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Q_fTXT-0n83DQaBUSn_oGQzMBwfTx50yH2U9_mkPtF8",
        {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({"tenantId": accounts[0].tenantId}),
        })
          .then((response) => handleVerify(response.headers.get("x-ms-has-access")))
          .catch(() => handleVerify(false))
          toggleIsLoading(false)
          } 
       
return (
    <AuthorizationContext.Provider value={isAuthorizied}>
        <AuthorizationUpdateContext.Provider value={toggleVerify}>
        {children}
        </AuthorizationUpdateContext.Provider>
    </AuthorizationContext.Provider>
)
}