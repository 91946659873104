import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
export const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = async (loginType) => {
        if (loginType === "popup") {
         
           /*  instance.loginPopup(loginRequest).catch((e) => {
                console.log(e);
            }); */
            await instance.loginPopup(loginRequest)
            //.then(response => {console.log(response)})
            .catch((e) => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required" 
                || error.errorCode === "invalid_grant") {
                    console.log(error.errorCode)
                    instance.acquireTokenRedirect(loginRequest);
                }
            });
        }
    }
    return (
       
        <Button variant="secondary" onClick={() => handleLogin("redirect")}>Sign In</Button>
    )
}


 {/* <DropdownButton variant="secondary" className="ml-auto" drop="start" title="Sign In">
            <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>Sign in using Popup</Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item>
        </DropdownButton> */}