import React, { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../authConfig";
import { devopsRequest } from "../authConfig";
import { FetchPlansByProject } from '../devops';
import { useToggleLoading, useIsLoading } from '../LoadingContext';
import Form from 'react-bootstrap/Form';

export const PlanDropDown = ({selectedOrg, selectedProject, setSelectedPlan, setSelectedSuite}) => {

    const [plans, setPlans] = useState([]);
    const { instance, accounts } = useMsal();
    
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

    const getPlanData = (data) => {
        if (data.value.length <= 0) {
            setPlans([])
            setSelectedPlan({})
            setSelectedSuite({})
            return;
        }
        setPlans(data.value) 
        setSelectedPlan(data.value[0])
     }

     useEffect(() => {
        const controller = new AbortController();
        async function RequestPlans() {
            instance
            .acquireTokenSilent({
                ...devopsRequest,
                account: accounts[0],
            })
            .then(async (response) => {
                toggleIsLoading(true)
                await FetchPlansByProject(selectedOrg.accountName, selectedProject.name,getPlanData,response.accessToken, controller.signal);
                toggleIsLoading(false)
            }).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required") {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: accounts[0],
                    });
                }
            });
        }
       
        if (isLoading) return;
        
        if (selectedProject === undefined) return;
        if (Object.keys(selectedProject).length > 0){
            RequestPlans();
        } 

        return () => {
            controller.abort();
        }
    }, [selectedProject]) 

    const handler = (e) => {
        var plan = plans.find((plan) => plan.id == e.target.value)
       setSelectedPlan(plan) 
    }
 
    return (
        <>
        <Form.Select disabled={isLoading} size="lg" onChange={handler}>
            {plans.length > 0 ? plans.map(a=> <option key={a?.id} areapath={a.area?.name} value={a.id}>{a?.name}</option>) : <option key={Math.random()}>No plans found</option>}
        </Form.Select>   
        </> 
    ); 
};