import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import '../styles/App.css';
import { useIsAuthenticated, useMsal} from '@azure/msal-react';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';

import image from '../images/Techlogicgraagul.png'

export const PageLayout = (props) => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <Navbar bg="dark" variant="dark" className="navbarStyle">
                <a className="navbar-brand" href="/">
                <img className='navbar-logo' src={image}/>
                </a>
                
                <div className="collapse navbar-collapse justify-content-end">
                    {accounts.length > 0 ? <>  <span className="username">{accounts[0].name}</span>
                 
                    <SignOutButton />
                    </> : <SignInButton />}
                </div>
            </Navbar>
            
            <br />
            <br />
            {props.children}
        </>
    );
};
