import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useMsal } from '@azure/msal-react';
import { devopsRequest, graphConfig, loginRequest } from "../authConfig";
import { useFetchOrgs } from "../useFetch";
import { useIsLoading, useToggleLoading } from "../LoadingContext";
import { useAuthorization } from "../AuthorizationContext";
import { useInfo, useInfoUpdate} from "../InformativeContext";

export const OrganizationDropDown = ({setSelectedOrg}) => {

    const [orgs, setOrgs] = useState([]);
    const { instance, accounts } = useMsal();
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()
    const isVerifyed = useAuthorization()
    const info = useInfo()
    const updateInfo = useInfoUpdate()

    const getOrgData = (data) => { 
        if (!isVerifyed) return;
        if (data.value.length <= 0) return;
        setOrgs(data.value) 
        setSelectedOrg(data.value[0])
     }

    useEffect(() => {  
        async function RequestOrgs() {
            instance
            .acquireTokenSilent({
                ...devopsRequest,
                account: accounts[0],
            })
            .then(async (response) => {   
                toggleIsLoading(true)
                await FetchOrganizations(getOrgData,response.accessToken);
                toggleIsLoading(false)
            }).catch(error => {
                if (error.errorCode === "consent_required"
                || error.errorCode === "interaction_required"
                || error.errorCode === "login_required" 
                || error.errorCode === "invalid_grant") {
                    console.log(error.errorCode)
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: accounts[0],
                    });
                }
            });
        }
        if (isLoading) return;
        if (!isVerifyed) return;
        RequestOrgs();
    }, [])

    const FetchOrganizations = async (updateAction, accessToken) => {
        if (!isVerifyed) return;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        let publicId = await fetchPublicId(accessToken)
        return fetch("https://app.vssps.visualstudio.com/_apis/accounts?memberId="+publicId+"&api-version=6.0", options)
          .then(handleHttpErrors)
          .then((data) => updateAction(data))
          .catch((error) => updateInfo({type: "error", message: "Error fetching organizations"}))
    }


    const fetchPublicId = async (accessToken) => {
        if (!isVerifyed) return;
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        return await fetch("https://app.vssps.visualstudio.com/_apis/profile/profiles/me?api-version=6.0", options)
        .then(handleHttpErrors)
        .then((data) => data.id)
    
    }

    function handleHttpErrors(res) {
        if (!res.ok) {
          return Promise.reject({ status: res.status, fullError: res.json() });
        }
        return res.json();
      }


    const handler = (e) => {
        var org = orgs.find((org) => org.accountName == e.target.value)
       setSelectedOrg(org)
       
    }

    return (
        <>
        <Form.Select disabled={isLoading} onChange={handler} size="lg">
       {/*      {orgs.map(a => <option key={a.accountId}>{a.accountName}</option>)} */}
            {orgs.length > 0 ? orgs.map(a=> <option key={a.accountId}>{a.accountName}</option>) : <option key="0">No organizations found</option>}
        </Form.Select>   
        </> 
    );
};