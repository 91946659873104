import { devopsConfig } from "./authConfig";

/**
 * Attaches a given access token to a Azure Devops API call. Returns information about the user
 * @param accessToken
 * @param endpoint
 * @param updateAction 
 */
import React from 'react'



export const FetchOrganizations = async (updateAction, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    let publicId = await fetchPublicId(accessToken)
    console.log(accessToken)
    return fetch("https://app.vssps.visualstudio.com/_apis/accounts?memberId="+publicId+"&api-version=6.0", options)
      .then(handleHttpErrors)
      .then((data) => updateAction(data))
      .catch(error => console.log(error));
}

export const FetchProjects = async (organization,updateAction, accessToken, signal) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
        signal: signal
    };
    if (organization === undefined) return;
    return fetch("https://dev.azure.com/"+organization+"/_apis/projects?api-version=6.0", options)
      .then(handleHttpErrors)
      .then((data) => updateAction(data))
      .catch(error => console.log(error));
}

export const FetchRootQuerys = async (organization,project,updateAction, accessToken, signal) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
        signal: signal
    };
    if (organization === undefined) return;
    return fetch("https://dev.azure.com/"+organization+"/"+project+"/_apis/wit/queries?&$depth=2&api-version=7.0", options)
      .then(handleHttpErrors)
      .then((data) => updateAction(data))
      .catch(error => console.log(error));
}

export const FetchQuerys = async (organization,project,root,updateAction, accessToken, signal) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
        signal: signal
    };
    if (organization === undefined) return;
    return fetch("https://dev.azure.com/"+organization+"/"+project+"/_apis/wit/queries/"+root+"?api-version=7.0", options)
      .then(handleHttpErrors)
      .then((data) => updateAction(data))
      .catch(error => console.log(error));
}

export const fetchPublicId = async (accessToken) => {

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return await fetch("https://app.vssps.visualstudio.com/_apis/profile/profiles/me?api-version=6.0", options)
    .then(handleHttpErrors)
    .then((data) => data.id)

}


export const FetchPlansByProject = async (organization, project,updateAction, accessToken, signal) => {
    if (accessToken === "") return;
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
        signal: signal
    };
    if (organization === undefined || project === undefined) return;
    return fetch("https://dev.azure.com/"+organization+"/"+project+"/_apis/test/plans?api-version=5.0", options)
      .then(handleHttpErrors)
      .then((data) => updateAction(data))
      .catch(error => console.log(error));
}

export const FetchSuitesByPlan = async (organization, project, planId, updateAction, accessToken) => {
    if (organization === "" || project === undefined || planId === "" || accessToken === "") return;
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
       const data = await fetch("https://dev.azure.com/"+organization+"/"+project+"/_apis/test/plans/"+planId+"/suites?api-version=5.0", options)
      .then(handleHttpErrors)
      .then((data) => data.value);
      const dataIds = data.map(d=>d.id);
      const promises = dataIds.map(id => 
        fetch("https://dev.azure.com/"+organization+"/"+project+"/_apis/wit/workitems/"+id+"?api-version=5.0", options)
          .then(handleHttpErrors));
          
    Promise.all(promises)
        .then(results => {
            const newData = results.map(result => ({
                id: result?.id,
                areapath: result?.fields["System.AreaPath"],
                name: result?.fields["System.Title"],
            }));
            updateAction(newData);
        });}

export const RequestReport = async (organization, project,plan,suite, mailTo, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "organization": organization.accountName,
            "project": project.name,
            "planId": plan.id,
            "planName": plan.name,
            "suiteId": suite.id,
            "mailTo": mailTo
        })
    };
    return fetch("https://devopsrapporter-test.azurewebsites.net/api/Function1_HttpStart?code=sPXAGRpjE4vSwEdrG4VCTBeioDkfG92a3zrV9aZLOwTrAzFunRAB5w==", options)
      .then(handleHttpErrors)
      //.then((data) => updateAction(data));
}

export const SendMail = async (mailTo, isUrs) => {
    const options = {
        method: "POST",
        body: JSON.stringify({
            "isURS": isUrs,
            "mailTo": mailTo
        })
    };
    return fetch("https://prod-201.westeurope.logic.azure.com:443/workflows/95a43fbf63ce48e18bf7d3eb8c05b7ae/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=BybgMN1FbRrAVbTzryaQSf8dPjK2ibOYKN_SdivdqHM", options)
      .then(handleHttpErrors)
      //.then((data) => updateAction(data));
}

export const RequestURSReport = async (organization, project,query1, mailTo,tenant, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "org": organization.accountName,
            "project": project.name,
            "query": query1.id,
            "name": query1.name,
            "token": accessToken,
            "mailTo": mailTo,
            "tenant":tenant
        })
    };
    return fetch("https://ursautomation.azurewebsites.net/api/Function1?code=36H5RVvCnoiseW2NqC3KN8IB4PoyOZboXdhYZp-Akye5AzFuGXmr2A==", options)
      .then(handleHttpErrors)
      //.then((data) => updateAction(data));
}

export const RequestTraceReport = async (organization, project,query,plan, mailTo,tenant, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "org": organization.accountName,
            "project": project.name,
            "planId":plan.id,
            "included": [],
            "excluded": [],
            "name": query.name,
            "id": query.id,
            "token": accessToken,
            "mailTo": mailTo,
            "tenant":tenant
        })
    };
    return fetch("https://ursautomationworkitemrelations.azurewebsites.net/api/OrchestrationStarter?code=dYUhSGHEcd6m0pMD7ajK4JBfVtP5SMDoqOAeuLsrp8GKAzFuBUow0w==", options)
      .then(handleHttpErrors)
      //.then((data) => updateAction(data));
}

function handleHttpErrors(res) {
    if (!res.ok) {
      return Promise.reject({ status: res.status, fullError: res.json() });
    }
    return res.json();
  }

