import React, { useState } from 'react';
import './styles/App.css';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import FrontPage from './components/ReportsPage';
import LoadingProvider from './LoadingContext';
import ReactLoading from "react-loading";
import { useIsLoading, useToggleLoading } from "./LoadingContext";
import { useAuthorization, useAuthorizationUpdate, AuthorizationProvider } from './AuthorizationContext';
import { useInfo, useInfoUpdate, InformationProvider } from './InformativeContext';
import { useEffect } from 'react';


const MainContent = () => {

    const { instance, accounts } = useMsal();
    
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()
    const isVerifyed = useAuthorization()
    const checkVerifyed = useAuthorizationUpdate()
    const info = useInfo();
    const updateInfo = useInfoUpdate();
       

  

    return (
        <div className="App">
             <InformationProvider>
            <LoadingProvider>
            <AuthenticatedTemplate>
            {isVerifyed? <FrontPage/> :
            <>
                {!isLoading ? "" : <div style={{
                    margin: 'auto',
                    width: '50%',
                    padding: '10px',
                    }}> <h1 className="card-title">Verifying access to reports engine</h1><br/><br/>
                        <ReactLoading style={{height: 50, width: 50, margin: 'auto', fill: '#FF5733'}} type='spin'/>
                    </div>}
                              
                <div>{!isLoading ?
                <>
                <h1>You do not have access to this product yet</h1>
                <h5>If you think this is mistake, contact us at reports@tech-logic.dk</h5></> : ""} </div>
                </>
            }

            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to begin requesting DevOps Reports.</h5>
            </UnauthenticatedTemplate>
        
            </LoadingProvider>
            </InformationProvider>
        </div>
    );
};

export default function App() {
 
    return (
        <LoadingProvider>
           
                <AuthorizationProvider>
                    <PageLayout>
                      <MainContent />
                    </PageLayout>
                </AuthorizationProvider>
          
        </LoadingProvider>
    );
}



/* const MainContent = () => {

    const { instance, accounts } = useMsal();
    const [isVerifyed, setIsVerifyed ] = useState(false)
    const isLoading = useIsLoading()
    const toggleIsLoading = useToggleLoading()

        const handleVerify = async (data) => {
            setIsVerifyed(data)
        }

        useEffect(() => {

            const verifyTenant = async (callback) => {
                toggleIsLoading(true)
                await fetch("https://prod-216.westeurope.logic.azure.com:443/workflows/76defe3e06574e28bafcc44576f3dac6/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Q_fTXT-0n83DQaBUSn_oGQzMBwfTx50yH2U9_mkPtF8",
                {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify({"tenantId": accounts[0].tenantId}),
                })
                  .then((response) => callback(response.headers.get("x-ms-has-access")))
                  .catch((error) => callback(false))
                  toggleIsLoading(false)
                  }
            if (isVerifyed) return;
            if (accounts.length <= 0) return;
           
            verifyTenant(handleVerify)
        }, [accounts.length])
    return (
        <div className="App">
            <LoadingProvider>
            <AuthenticatedTemplate>
               
            {isVerifyed? <FrontPage/> :
               
                !isLoading ? "" : <div style={{
                    margin: 'auto',
                    width: '50%',
                    padding: '10px',
                    }}> <h1 className="card-title">Verifying access to reports engine</h1><br/><br/>
                        <ReactLoading style={{height: 50, width: 50, margin: 'auto', fill: '#FF5733'}} type='spin'/>
                    </div>
            }

            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to begin requesting DevOps Reports.</h5>
            </UnauthenticatedTemplate>
            </LoadingProvider>
        </div>
    );
}; */